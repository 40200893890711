import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HomeIcon from '@material-ui/icons/Home';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import HelpIcon from '@material-ui/icons/Help';
import Progress from '../components/forms/shared/Progress'
import PersonIcon from '@material-ui/icons/Person';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CreateIcon from '@material-ui/icons/Create';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import BuildIcon from '@material-ui/icons/Build';
import { sortBy } from 'lodash'
import VideocamIcon from '@material-ui/icons/Videocam';

export const getTaskIcon = (task) => {
    task = task.toUpperCase()
    let icon
    switch (task) {
        case 'CONFIGURELIFEINSURANCE':
            icon = PersonIcon
            break
        case 'DEFINEBANKACCOUNT':
            icon = AttachMoneyIcon
            break
        case 'SIGNOFFER':
            icon = CreateIcon
            break
        case 'VERIFYADDRESSWITHAUTONOMATION':
            icon = HomeIcon
            break
        case 'VERIFYADDRESSWITHGEOCODING':
            icon = HomeIcon
            break
        case 'VERIFYBANKACCOUNT':
            icon = AttachMoneyIcon
            break
        case 'VERIFYEMAIL':
            icon = AlternateEmailIcon
            break
        case 'VERIFYIDENTITYWITHAUTONOMATION':
            icon = HowToRegIcon
            break
        case 'VERIFYIDENTITYWITHBIOMETRICS':
            icon = HowToRegIcon
            break
        case 'VERIFYMOBILENUMBER':
            icon = MobileFriendlyIcon
            break
        case 'VERIFYAFI':
            icon = FindInPageIcon
            break
        case 'VERIFYUSERDATA':
            icon = HowToRegIcon
            break
        case 'VERIFYBUSINESSDATA':
            icon = BusinessIcon
            break
        case 'VERIFYBUSINESSOPERATION':
            icon = BusinessIcon
            break
        case 'VERIFYSIGNATURE':
            icon = CreateIcon
            break
        case 'VERIFYIDENTITYWITHVIDEOCONFERENCE':
            icon = VideocamIcon
            break
        case 'VERIFYIDENTITYWITHPHONECALL':
            icon = PhoneIcon
            break
        case 'VERIFYRETIREMENTDOCUMENTS':
            icon = FileCopyIcon
            break
        case 'VERIFYCOMPLEMENTARYDOCUMENTATION':
            icon = FileCopyIcon
            break
        case 'PROOFINCOME':
            icon = FileCopyIcon
            break
        case 'PROOFPENSION':
            icon = FileCopyIcon
            break
        case 'PROOFINCOME2':
            icon = FileCopyIcon
            break
        case 'PROOFPENSION2':
            icon = FileCopyIcon
            break
        case 'VERIFYBUSINESSOWNERSHIP':
            icon = BusinessIcon
            break
        case 'INSTALLCUSTOMERAPP':
            icon = PhoneIcon
            break
        case 'VERIFYPERSONALREFERENCES':
            icon = PersonIcon
            break
        case 'VERIESTADODECUENTA':
            icon = FileCopyIcon
            break
        case 'PROOFINCOMELOWCOST':
            icon = FileCopyIcon
            break
        case 'VERICUENTA':
            icon = AttachMoneyIcon
            break
        case 'PROOFINCOMEFORMAL':
            icon = FileCopyIcon
            break
        case 'VERYDESTINITY':
            icon = FileCopyIcon
            break
        case 'AXIINGRESOFORMAL':
            icon = FileCopyIcon
            break
        case 'VERIINE':
            icon = PersonIcon
            break
        case 'OFFER':
            icon = AttachMoneyIcon
            break
        default:
            icon = BuildIcon
    }

    return icon
}

export const getSpecialStatusCaseFormer = (status, kind) => {
    let statusReturn
    status = status?.toUpperCase()
    kind = kind?.toUpperCase()
    const specialCase = ['VERIFYCREDITPOLICIES', 'VERIFYBUREAU', 'VERIFYADDRESSWITHGEOCODING', 'VERIFYBANKACCOUNT', 'VERIFYIDENTITYWITHPHONECALL']
    if (specialCase.indexOf(kind) >= 0 && (['NEW', 'NEEDSCORRECTION'].includes(status))) { // se encuentra dentro de los casos speciales
        statusReturn = 'InProgress'
    }

    return statusReturn
}

export const getSpecialStatusCase = (status, kind) => {
    let statusReturn
    status = status?.toUpperCase()
    kind = kind?.toUpperCase()
    const specialCase = ['VERIFYCREDITPOLICIES', 'VERIFYBUREAU', 'VERIFYADDRESSWITHGEOCODING', 'VERIFYEMAIL', 'VERIFYBANKACCOUNT', 'VERIFYIDENTITYWITHPHONECALL']
    if (specialCase.indexOf(kind) >= 0 && (['NEW', 'NEEDSCORRECTION'].includes(status))) { // se encuentra dentro de los casos speciales
        statusReturn = 'InProgress'
    }

    return statusReturn
}

export const getSpecialStatusCasePensionados = (status, kind) => {
    let statusReturn
    status = status?.toUpperCase()
    kind = kind?.toUpperCase()
    const specialCase = ['VERIFYCREDITPOLICIES', 'VERIFYBUREAU', 'VERIFYADDRESSWITHGEOCODING', 'VERIFYEMAIL', 'VERIFYIDENTITYWITHPHONECALL']
    if (specialCase.indexOf(kind) >= 0 && (['NEW', 'NEEDSCORRECTION'].includes(status))) { // se encuentra dentro de los casos speciales
        statusReturn = 'InProgress'
    }

    return statusReturn
}

export const getOrderArray = (array, field = 'status', order = { new: 1, needscorrection: 2, inprogress: 3, completed: 4, error: 5 }) => {
    return sortBy(array, element => {
        return order[element[field]?.toLowerCase()];
    })
}

export const getTaskStatusIcon = (status, kind) => {
    status = status.toUpperCase()

    let icon

    switch (status) {
        case 'COMPLETED':
            icon = CheckIcon
            break
        case 'INPROGRESS':
            icon = Progress
            break
        case 'ERROR':
            icon = CloseIcon
            break
        case 'NEEDSCORRECTION':
            icon = RefreshIcon
            break
        default:
            icon = HelpIcon
            break
    }

    return icon
}

export const getTaskUrlFormer = (task) => {
    task = task.toUpperCase()

    let url

    switch (task) {
        case 'CONFIGURELIFEINSURANCE':
            url = '../seguro-vida'
            break
        case 'SIGNOFFER':
            url = '../firma-digital'
            break
        case 'VERIFYADDRESSWITHAUTONOMATION':
            url = '../verificacion-comprobante'
            break
        case 'VERIFYADDRESSWITHGEOCODING':
            url = ''
            break
        case 'VERIFYBANKACCOUNT':
            url = '../verificacion-bancaria'
            break
        case 'DEFINEBANKACCOUNT':
            url = '../verificacion-bancaria'
            break
        case 'VERIFYEMAIL':
            url = '../verificacion-correo'
            break
        case 'VERIFYIDENTITYWITHAUTONOMATION':
            url = '../verificacion-credencial'
            break
        case 'VERIFYIDENTITYWITHBIOMETRICS':
            url = '../video-verificacion'
            break
        case 'VERIFYMOBILENUMBER':
            url = '../verificacion-telefono'
            break
        case 'VERIFYAFI':
            url = ''
            break
        case 'VERIFYSIGNATURE':
            url = '../firma-electronica'
            break
        case 'VERIFYUSERDATA':
            url = '../correcion-datos'
            break
        case 'VERIFYBUSINESSDATA':
            url = '../verificacion-datos-negocio'
            break
        case 'VERIFYIDENTITYWITHVIDEOCONFERENCE':
            url = '../video-llamada'
            break
        case 'VERIFYBUSINESSOPERATION':
            url = '../carga-archivos?operationType=BusinessOperation'
            break
        case 'VERIFYIDENTITYWITHPHONECALL':
            url = ''
            break
        case 'VERIFYRETIREMENTDOCUMENTS':
            url = '../carga-archivos-jubilado'
            break
        case 'VERIFYCOMPLEMENTARYDOCUMENTATION':
            url = '../carga-archivos?operationType=BusinessComplementary'
            break
        case 'PROOFINCOME':
            url = '../carga-ingresos?operationType=ProofIncome'
            break
        case 'PROOFPENSION':
            url = '../carga-archivos-pensionados?operationType=ProofPension'
            break
        case 'PROOFINCOME2':
            url = '../carga-ingresos?operationType=ProofIncome2'
            break
        case 'PROOFPENSION2':
            url = '../carga-archivos-pensionados?operationType=ProofPension2'
            break
        case 'VERIFYBUSINESSOWNERSHIP':
            url = '../carga-archivos?operationType=BusinessOwnership'
            break
        case 'INSTALLCUSTOMERAPP':
            url = '../descargar-aplicacion'
            break
        case 'VERIFYPERSONALREFERENCES':
            url = '../referencias-personales'
            break
        case 'VERIESTADODECUENTA':
            url = '../carga-archivos?operationType=AccountStatement'
            break
        case 'PROOFINCOMELOWCOST':
            url = '../carga-archivos?operationType=ProofIncomeLowCost'
            break
        case 'VERICUENTA':
            url = '../verificacion-bancaria'
            break
        case 'PROOFINCOMEFORMAL':
            url = '../carga-ingresos?operationType=ProofIncomeFormal'
            break
        case 'VERYDESTINITY':
            url = '../destino-credito'
            break
        default:
            url = ''
    }
    return url
}

export const getTaskUrl = (task) => {
    task = task.toUpperCase()

    let url

    switch (task) {
        case 'CONFIGURELIFEINSURANCE':
            url = '../seguro-vida'
            break
        case 'SIGNOFFER':
            url = '../firma-digital'
            break
        case 'VERIFYADDRESSWITHAUTONOMATION':
            url = '../verificacion-comprobante'
            break
        case 'VERIFYADDRESSWITHGEOCODING':
            url = ''
            break
        case 'VERIFYBANKACCOUNT':
            url = '../verificacion-bancaria'
            break
        case 'DEFINEBANKACCOUNT':
            url = '../verificacion-bancaria'
            break
        case 'VERIFYEMAIL':
            url = '../verificacion-correo'
            break
        case 'VERIFYIDENTITYWITHAUTONOMATION':
            url = '../verificacion-credencial'
            break
        case 'VERIFYIDENTITYWITHBIOMETRICS':
            url = '../video-verificacion'
            break
        case 'VERIFYMOBILENUMBER':
            url = '../verificacion-telefonica'
            break
        case 'VERIFYAFI':
            url = ''
            break
        case 'VERIFYSIGNATURE':
            url = '../firma-electronica'
            break
        case 'VERIFYUSERDATA':
            url = '../correcion-datos'
            break
        case 'VERIFYBUSINESSDATA':
            url = '../verificacion-datos-negocio'
            break
        case 'VERIFYIDENTITYWITHVIDEOCONFERENCE':
            url = '../video-llamada'
            break
        case 'VERIFYBUSINESSOPERATION':
            url = '../carga-archivos?operationType=BusinessOperation'
            break
        case 'VERIFYIDENTITYWITHPHONECALL':
            url = ''
            break
        case 'VERIFYRETIREMENTDOCUMENTS':
            url = '../carga-archivos-jubilado'
            break
        case 'VERIFYCOMPLEMENTARYDOCUMENTATION':
            url = '../carga-archivos?operationType=BusinessComplementary'
            break
        case 'PROOFINCOME':
            url = '../carga-ingresos?operationType=ProofIncome'
            break
        case 'PROOFPENSION':
            url = '../carga-archivos-pensionados?operationType=ProofPension'
            break
        case 'PROOFINCOME2':
            url = '../carga-ingresos?operationType=ProofIncome2'
            break
        case 'PROOFPENSION2':
            url = '../carga-archivos-pensionados?operationType=ProofPension2'
            break
        case 'VERIFYBUSINESSOWNERSHIP':
            url = '../carga-archivos?operationType=BusinessOwnership'
            break
        case 'INSTALLCUSTOMERAPP':
            url = '../descargar-aplicacion'
            break
        case 'VERIFYPERSONALREFERENCES':
            url = '../referencias-personales'
            break
        case 'VERIESTADODECUENTA':
            url = '../carga-archivos?operationType=AccountStatement'
            break
        case 'PROOFINCOMELOWCOST':
            // url = '../comprobante-ingresos'
            url = '../carga-archivos?operationType=ProofIncomeLowCost'
            break
        case 'VERICUENTA':
            url = '../verificacion-bancaria'
            break
        case 'PROOFINCOMEFORMAL':
            url = '../carga-ingresos?operationType=ProofIncomeFormal'
            break
        case 'VERYDESTINITY':
            url = '../destino-credito'
            break
        case 'AXIINGRESOFORMAL':
            url = ''
            break
        case 'OFFER':
            url = '../tu-oferta'
            break
        case 'VERIINE':
            url = ''
            break
        default:
            url = ''
    }

    return url
}

export const getTaskActionText = (task) => {
    task = task.toUpperCase()

    let actionText

    switch (task) {
        case 'CONFIGURELIFEINSURANCE':
            actionText = 'AGREGA BENEFICIARIOS'
            break
        case 'SIGNOFFER':
            actionText = 'FIRMA TU OFERTA'
            break
        case 'VERIFYADDRESSWITHAUTONOMATION':
            actionText = 'VERIFICA DOMICILIO'
            break
        case 'VERIFYADDRESSWITHGEOCODING':
            actionText = 'VERIFICA DOMICILIO (...)'
            break
        case 'VERIFYBANKACCOUNT':
            actionText = 'VERIFICA CUENTA (BANCARIA)'
            break
        case 'DEFINEBANKACCOUNT':
            actionText = 'VERIFICA CUENTA (BANCARIA)'
            break
        case 'VERIFYEMAIL':
            actionText = 'VERIFICA CORREO'
            break
        case 'VERIFYIDENTITYWITHAUTONOMATION':
            actionText = 'VERIFICA IDENTIDAD'
            break
        case 'VERIFYIDENTITYWITHBIOMETRICS':
            actionText = 'VERIFICA IDENTIDAD'
            break
        case 'VERIFYMOBILENUMBER':
            actionText = 'VERIFICA TELÉFONO'
            break
        case 'VERIFYAFI':
            actionText = 'VERIFICA TU CREDITO (...)'
            break
        case 'VERIFYSIGNATURE':
            actionText = 'FIRMA TU CONTRATO'
            break
        case 'VERIFYBUSINESSOPERATION':
            actionText = 'SUBIR DOCUMENTOS'
            break
        case 'VERIFYUSERDATA':
            actionText = 'VERIFICA TUS DATOS'
            break
        case 'VERIFYBUSINESSDATA':
            actionText = 'COMPLETA TUS DATOS'
            break
        case 'VERIFYIDENTITYWITHVIDEOCONFERENCE':
            actionText = 'INICIAR VIDEOLLAMADA'
            break
        case 'VERIFYIDENTITYWITHPHONECALL':
            actionText = 'VERIFICACION TELEFONICA (...)'
            break
        case 'VERIFYRETIREMENTDOCUMENTS':
            actionText = 'SUBIR DOCUMENTOS'
            break
        case 'VERIFYCOMPLEMENTARYDOCUMENTATION':
            actionText = 'SUBIR DOCUMENTOS'
            break
        case 'PROOFINCOME':
            actionText = 'COMPROBANTES DE INGRESOS'
            break
        case 'PROOFPENSION':
            actionText = 'SUBIR DOCUMENTOS'
            break
        case 'PROOFINCOME2':
            actionText = 'COMPROBANTES DE INGRESOS'
            break
        case 'PROOFPENSION2':
            actionText = 'SUBIR DOCUMENTOS'
            break
        case 'VERIFYBUSINESSOWNERSHIP':
            actionText = 'SUBIR DOCUMENTOS'
            break
        case 'INSTALLCUSTOMERAPP':
            actionText = 'DESCARGA LA APP'
            break
        case 'VERIFYPERSONALREFERENCES':
            actionText = 'AGREGAR REFERENCIAS'
            break
        case 'VERIESTADODECUENTA':
            actionText = 'SUBIR ESTADO DE CUENTA'
            break
        case 'PROOFINCOMELOWCOST':
            actionText = 'COMPROBANTES DE INGRESOS'
            break
        case 'VERICUENTA':
            actionText = 'VERIFICA CUENTA (CLABE)'
            break
        case 'PROOFINCOMEFORMAL':
            actionText = 'COMPROBANTES DE INGRESOS'
            break
        case 'VERYDESTINITY':
            actionText = 'DESTINO DE CREDITO'
            break
        case 'AXIINGRESOFORMAL':
            actionText = ''
            break
        case 'OFFER':
            actionText = 'TU OFERTA'
            break
        case 'VERIINE':
            actionText = ''
            break
        default:
            actionText = 'EN MANTENIMIENTO'
    }

    return actionText
}